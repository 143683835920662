<template>
  <el-dialog title="添加学员" :visible.sync="dialogVisible" top="10vh" width="60%" @close="close">

    <div class="query-btn">
      <el-form ref="form" :model="formData" label-width="80px" inline>
        <el-form-item label="学员姓名">
          <el-input v-model="formData.name" @keydown.native.enter="getStudentList" placeholder="请输入学员姓名"/>
        </el-form-item>

        <el-form-item label="状态">
          <el-select v-model="formData.groupStatus" @change="getStudentList" clearable placeholder="请选择状态">
            <el-option label="未分班" value="0">未分班</el-option>
            <el-option label="已分班" value="1">已分班</el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <el-table ref="table" :data="dataSet" @select="selectionChange" max-height="300px">
      <el-table-column type="selection" width="auto" align="center"/>
      <el-table-column fixed prop="name" label="学生姓名"/>
      <el-table-column fixed prop="motherPhone" label="手机号"/>
      <el-table-column prop="createTime" label="录入时间"/>
      <el-table-column prop="groupStatus" label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.groupStatus === 1">已分班</span>
          <span v-else>未分班</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        style="margin-top: 20px;"
        background
        layout="prev, pager, next"
        :current-page.sync="formData.current"
        :page-size="formData.size"
        :total="total">
    </el-pagination>

    <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="addClass">加入班级</el-button>
        </span>

  </el-dialog>
</template>
<script>
export default {
  name: 'app',
  components: {},
  props: {
    classId: String,
    studentVisible: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      dialogVisible: this.studentVisible,
      studentSelecteds: [],
      formData: {
        groupStatus: '',
        name: "",
        current: 1,
        size: 10
      },
      dataSet: [],
      total: 0,

    }
  },
  methods: {
    close() {
      this.$emit("close")
    },
    //列表复选
    selectionChange(list, row) {
      let index = this.studentSelecteds.findIndex(i => i.id === row.id);
      if (index == -1 ) {
        this.studentSelecteds.push(row)
      } else {
        this.studentSelecteds.splice(index, 1);
      }
    },
    //添加班级学生
    async addClass() {
      if (!this.studentSelecteds.length) {
        this.$message.warning("请选择学生！")
        return
      }

      const res = await this.axios.post(`/class/addStudents`, {
            classId: this.classId,
            studentIds: this.studentSelecteds.map((item) => item.id),
          }, {headers: {'Content-Type': 'application/json'}}
      );
      if (res.result) {
        this.$message.success("加入班级成功！")
        this.$emit('initList')
      } else {
        this.$emit('close')
        this.$message.error(res.msg)
      }
    },
    //获取学生列表
    async getStudentList() {
      const res = await this.axios.post(`/system/student/queryByPage`, this.formData);
      if (res) {
        this.total = res.total;
        this.dataSet = res.records;
        if (this.studentSelecteds.length) {
          this.studentSelecteds.forEach(i => {
            let item = res.records.find(j => j.id === i.id);
            if (item) this.$nextTick(() => this.$refs.table.toggleRowSelection(item, true));
          })
        }
      }
    },

    async getClassStudentList() {
      let { name } = this.formData
      const res = await this.axios.post(`class/queryStudentsByClassId`, { classId: this.classId, groupStatus: 1 });
      if (res.result) {
        this.studentSelecteds.push(...res.json.records);
      }
    },
  },
  created() {
    this.getClassStudentList();
  },
  watch: {
    'formData.current': {
      handler: function (newVal, oldVal) {
        this.getStudentList();
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

