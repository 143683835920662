/**
 * 获取页面全体字典项
 */
export default {
    data() {
        return {
            gradeList: [],    //年级下拉
            classTypes: [],    //班型下拉
            // classStatus: [],    //班级状态
            campuList: [],    //所属校区
            lecturerList: [],    //授课教师
            courseList: [],    //课程
            statusList: [
                {label: "全部班级", key: "status", value: "1"},
                {label: "已开课班级", key: "status", value: "2"},
                {label: "待开课班级", key: "status", value: "3"},
                {label: "已结课班级", key: "status", value: "4"},
                {label: "待排课班级", key: "status", value: "5"}
            ]
        }
    },
    methods: {
        //获取年级
        async getGrade(subjectId) {
            const res = await this.axios.post(`/admin/courseSystem/query?subjectId=${subjectId}&relationType=GRADE`);
            if (res.result) {
                this.gradeList = res.json
            }
        },
        //获取班型
        async getClassType(subjectId) {
            const res = await this.axios.post(`/admin/courseSystem/query?subjectId=${subjectId}&relationType=COURSE_TYPE`);
            if (res.result) {
                this.classTypes = res.json
            }
        },
        //所属校区
        async getorgList() {
            const res = await this.axios.post(`/admin/school/query`);
            if (res.result) {
                this.campuList = res.json
            }
        },
        //授课教师
        async getLecturer() {
            const res = await this.axios.post(`/permission/user/queryAll`);
            if (res.result) {
                this.lecturerList = res.json
            }
        },
        //课程
        async getCourse(subjectId) {
            const res = await this.axios.post(`/course/queryBySubject?subject=${subjectId}`);
            if (res.result) {
                this.courseList = res.json
            }
        },
    },
    created() {
        this.getorgList()
        this.getLecturer()
    }
}
