<template>
	<el-dialog title="查看学员" :visible.sync="dialogVisible" width="60%" @close="close">
		<div class="query-btn">
			<el-form ref="form" :model="formData" label-width="80px" inline>
				<el-form-item label="学员姓名">
					<el-input v-model="formData.name" @keydown.native.enter="getStudentList" placeholder="请输入学员姓名" />
				</el-form-item>
			</el-form>
		</div>
			<el-table ref="table" :data="dataSet" max-height="500px">
				<el-table-column fixed prop="name" label="学生姓名"/>
				<el-table-column fixed prop="motherPhone" label="手机号"/>
				<el-table-column prop="createTime" label="录入时间"/>
				<el-table-column prop="groupStatus" label="状态">
					<template slot-scope="scope">
						<span v-if="scope.row.groupStatus === 1">已分班</span>
						<span v-else>未分班</span>
					</template>
				</el-table-column>
			</el-table>

			<el-pagination 
				layout="prev, pager, next" 
				:total="formData.total" 
				:page-size="formData.size" 
				:current-page.sync="formData.current"
				@current-change="getStudentList()"
			/>
	</el-dialog>
</template>
<script>
export default {
	name: 'app',
	components: {},
	props: {
		classId: String,
		id: String
	},
	data() {
		return {
			dialogVisible: true,
			studentSelecteds: [],
			formData: {
				groupStatus : '',
				name: '',
				size: 10,
				current: 1,
				total: 0
			},
			dataSet: []
		}
	},
	methods: {
		close() { this.$emit('close') },
		//获取学生列表
		async getStudentList() {
			let { name } = this.formData
			const res = await this.axios.post(`class/queryStudentsByClassId`, { classId: this.id, ...this.formData });
			if (res.result) {
				this.dataSet = res.json.records;
				this.formData.total = res.json.total;
				this.formData.current = res.json.current;
			}
		},
	},
	created() {
		this.getStudentList()
	}
}
</script>

