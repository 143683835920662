<template>
  <div>
    <div class="query-btn">
      <el-form ref="form" :model="formData" label-width="80px" inline>
        <el-form-item label="教师名称">
          <el-input placeholder="请输入授课教师名称" v-model="formData.teacherName"></el-input>
        </el-form-item>
        <el-form-item label="班级名称">
          <el-input placeholder="请输入班级名称" v-model="formData.name"></el-input>
        </el-form-item>
        <el-form-item label="学科">
          <el-cascader v-model="formData.subjectId" :options="options" @change="subjectChange" clearable placeholder="请选择学科" />
        </el-form-item>
        <el-form-item label="年级">
          <el-select v-model="formData.gradeId" clearable placeholder="请选择年级">
            <el-option v-for="item in gradeList" :label="item.relationName" :value="item.relationValue" :key="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="班型">
          <el-select v-model="formData.classTypeId" clearable placeholder="请选择班型">
            <el-option v-for="item in classTypes" :label="item.relationName" :value="item.relationValue" :key="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item style="float: right;">
          <el-button @click="initPageList()" type="primary">查询</el-button>
          <el-button v-if="systemName !== 'sike'" @click="editClass(null)" type="primary">创建班级</el-button>
        </el-form-item>
      </el-form>
    </div>
    <cus-table ref="table" url="/class/queryByPage" method="post" :autoRequest="false">
      <el-table-column prop="title" label="班级名称"/>
      <el-table-column prop="subjectName" label="学科"/>
      <el-table-column prop="gradeName" label="年级"/>
      <el-table-column prop="classTypeName" label="班型"/>
      <el-table-column prop="teacherName" label="授课教师"/>
      <el-table-column prop="orgName" label="所属校区"/>
      <el-table-column fixed="right" label="操作" :width="systemName !== 'sike' ? '260px' : '100px'">
        <template slot-scope="scope">
          <el-button type="text" @click="previewStudentId = scope.row.id">查看学员</el-button>
          <template v-if="systemName !== 'sike'">
            <el-divider direction="vertical"/>
            <el-button type="text" @click="addStudent(scope.row)">添加学员</el-button>
            <el-divider direction="vertical"/>
            <el-button type="text" @click="editClass(scope.row)">修改</el-button>
            <el-divider direction="vertical"/>
            <el-button type="text" @click="remove(scope.row)">删除</el-button>
          </template>
        </template>
      </el-table-column>
    </cus-table>
    <el-drawer
      :title="classTitle"
      :visible.sync="isDrawer"
      :modal="false"
      :show-close="false"
      custom-class="customDrawerclass">
        <template v-if="isDrawer">
          <createClasses
            ref="drawerRef"
            :data="editForm"
            :options="options"
            @initList="()=>{this.initPageList(); this.isDrawer=false}"
          />
        </template>
    </el-drawer>
    <!-- 添加学员 -->
    <template v-if="studentVisible">
      <addStudent
        :classId="classId"
        :studentVisible="studentVisible"
        @close="studentVisible = false"
        @initList="()=>{this.initPageList(); this.studentVisible=false}"
      />
    </template>
    <!-- 查看学员 -->
    <template v-if="previewStudentId">
      <previewStudentComponent :id="previewStudentId" @close="previewStudentId = null" />
    </template>
  </div>
</template>

<script>
import CusTable from '@/components/table'
import createClasses from './create-classes.vue'
import addStudent from './add-student.vue'
import dictMixins from "./dictMixins";
import previewStudentComponent from './preview-student.vue';

export default {
  mixins: [dictMixins],
  components: {CusTable, createClasses, addStudent, previewStudentComponent},
  data() {
    return {
      options: [{}, {}, {}],   //学科下拉
      isDrawer: false,
      studentVisible: false,
      classTitle: '',
      formData: {
        subjectId: [],
        name: '',
        teacherName: ''
      },
      editForm: null,
      classId: '',
      systemName: process.env.VUE_APP_SYSTEM_NAME,
      previewStudentId: null
    }
  },
  watch: {
    formData: {
      handler() {
        this.$nextTick(() => {
          this.initPageList()
        })
      },
      deep: true,
      immediate: true
    },
  },
  methods: {
    //添加学员
    addStudent({id}) {
      this.studentVisible = true;

      this.classId = id
    },
    //删除
    async remove(e) {
      let confirm = await this.$confirm('此操作将永久删除该班级, 是否继续?', '提示', {type: 'warning'});
      if (confirm) {
        const res = await this.axios.post(`/class/deleteClass?id=${e.id}`);
        res.result && this.$message.success('删除班级成功~！') && this.$refs.table.request();
      }
    },
    //新建、修改班级
    async editClass(data) {
      this.isDrawer = true;
      if (data == null) {
        this.classTitle = "创建班级"
        this.editForm = null
        return
      }
      this.classTitle = "修改班级"
      //获取详情
      const res = await this.axios.post(`/class/getClassById?id=${data.id}`);
      let {title, subjectId, gradeId, classTypeId, orgId, teacherId, courseId, id} = res.json

      this.$refs.drawerRef.refreshSelect(subjectId)

      let parentCode = ''   //获取学科父级code
      this.options.forEach((item) => {
        item.children.forEach((bean) => {
          if (bean.value === subjectId) {
            parentCode = item.value
          }
        })
      });

      this.editForm = {
        title, subjectId: [parentCode, subjectId], gradeId, classTypeId, orgId, teacherId, courseId, id
      }

    },
    subjectChange() {
      let subjectId = this.formData.subjectId[1]

      if (!this.formData.subjectId.length) {
        this.formData.gradeId = ""
        this.formData.classTypeId = ""

      }
      this.getGrade(subjectId)
      this.getClassType(subjectId)
      this.getCourse(subjectId)
    },
    initPageList() {
      let subjectId = this.formData.subjectId
      let data = {
        ...this.formData,
        subjectId: subjectId ? this.formData.subjectId[1] : ""
      }
      this.$refs.table.request(data)
    },     //获取学科
    async getSubject() {
      const res = await this.axios.post('/system/dictionary/querySubject');
      if (res.result) {
        Object.keys(res.json).forEach((key, index) => {
          let subjectParName = ''
          switch (key) {
            case 'high':
              subjectParName = '高中'
              break;
            case 'middle':
              subjectParName = '初中'
              break;
            case 'primary':
              subjectParName = '小学'
              break;
            default:
              subjectParName = '小学'
              break;
          }
          this.$set(this.options[index], 'value', key)
          this.$set(this.options[index], 'label', subjectParName)
          this.$set(this.options[index], 'children', res.json[key])
        });
        this.options.forEach((item, index) => {
          item.children.forEach((key) => {
            this.$set(key, 'value', key.code)
            this.$set(key, 'label', key.name)
          });
        });
      }
    },
  },
  mounted() {
    this.getSubject()
    this.initPageList()
  }
}
</script>

<style lang="scss" scoped>
.query-btn {
  &::v-deep .el-form-item {
    margin-right: 20px;
  }
  &::v-deep .el-form-item__label {
    padding: 0;
    text-align: center;
    border: solid 1px #DCDFE6;
    line-height: 38px;
    margin-right: -2px;
    vertical-align: bottom;
    background: #F5F7FA;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    color: #909399;
  }

  & /deep/ .el-input__inner {
    width: 250px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.add-btn {
  &:hover i,
  &:focus i {
    color: #fff;
  }

  i {
    color: #1AAFA7;
    transform: scale(1.3);
    margin-right: 6px;
  }
}
::v-deep .el-drawer__header {
  padding-bottom: 20px;
  background: #1AAFA7;
  color: #fff;
  text-align: center;
  height: 20px;
  line-height: 20px;
  margin-bottom: 0;
}

::v-deep .el-drawer__body {
  overflow: auto;
}
</style>
