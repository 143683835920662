<template>
    <div style="overflow-y: scroll; height: 100%;">
        <el-form ref="form" :model="formData" :rules="rules" class="create-classes-container">
        <el-form-item label="班级名称" prop="title">
          <el-input v-model="formData.title" placeholder="请输入班级名称" />
        </el-form-item>
        <el-form-item label="学科" prop="subjectId">
          <el-cascader
              placeholder="请选择学科"
              v-model="formData.subjectId"
              :options="options"
              @change="subjectChange"
          />
        </el-form-item>
        <el-form-item label="年级">
          <el-select v-model="formData.gradeId"    placeholder="请选择年级">
            <el-option v-for="item in gradeList" :label="item.relationName" :value="item.relationValue" :key="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="班型">
          <el-select v-model="formData.classTypeId"  placeholder="请选择班型">
            <el-option v-for="item in classTypes" :label="item.relationName" :value="item.relationValue" :key="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="所属校区">
          <el-select v-model="formData.orgId" placeholder="请选择所属校区">
            <el-option
                v-for="item in campuList"
                :key="item.id"
                :label="item.schoolName"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="授课教师">
          <el-select v-model="formData.teacherId" filterable placeholder="请选择授课教师">
            <el-option
                v-for="item in lecturerList"
                :key="item.id"
                :label="item.nickname"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="课程">
          <el-select v-model="formData.courseId" filterable placeholder="请选择课程">
            <el-option
                v-for="item in courseList"
                :key="item.id"
                :label="item.courseName"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
        <div class="create-class-btn">
          <el-button type="primary" @click="save">保存</el-button>
        </div>
      </el-form>
    </div>
</template>
<script>
    import dictMixins from "./dictMixins"
    export default {
        mixins: [dictMixins],
        props: {
            data: {
                type: Object,
                default: () => {
                }
            },
            options: {
                type: Array,
                default: () => []
            }
        },
        watch: {
            data: {
                handler(newVal) {
                    if (!newVal) {
                        return
                    }
                    this.formData = newVal
                },
                deep: true,
                immediate: true
            },
        },
        data() {
            return {
                formData: {
                    title: "", //  班级名称
                    subjectId: [], //学科id
                    gradeId: "",  //年级id
                    classTypeId: "", //班型id
                    orgId: "",  //校区id
                    teacherId: "",  //主讲教师ID
                    courseId: "", //课程id
                },
                rules: {
                    title: [
                        {required: true, message: '此项为必填项!', trigger: 'blur'},
                    ],
                    subjectId:[
                        {required: true, message: '此项为必填项!', trigger: 'change'},
                    ]
                }
            }
        },
        methods: {
            refreshSelect(subjectId){
                this.getGrade(subjectId)
                this.getClassType(subjectId)
                this.getCourse(subjectId)
            },
            subjectChange() {
                let subjectId = this.formData.subjectId[1]

                this.formData.gradeId = ''
                this.formData.classTypeId = ''
                this.formData.courseId = ''

                this.refreshSelect(subjectId)
            },
            async save() {
                let data = this.formData
                let formTemp = {
                    ...data,
                    subjectId: data.subjectId[1]
                };
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.axios.post(`/class/saveOrUpdate`, formTemp).then((res) => {
                            if (res.result) {
                                this.$message.success('保存成功！')
                                this.$emit("initList")
                            }else {
                                this.$message.error(res.msg)
                            }
                        })
                    }
                });
            }
        },
    }
</script>

<style scoped lang="less">
    .create-class-btn {
        text-align: right;
    }
    .create-classes-container {
        padding: 0 20px;
        .el-cascader,
        .el-select {
            width: 100%;
        }
    }
</style>
